#MainMenu {
	width: 70px;
	height: 100%;
	background-color: #2f7eb6;
	position: fixed;
	z-index: 8;
	top: 0;
	left: 0;
}

#Headline {
	height: 82px;
	border-bottom: solid 2px #ECF0F1;
}

#Middle {
	margin-left: 70px;
	background-color: #ECF0F1;
	padding: 12px;
	height: calc(100vh - 82px);	
	overflow-y: auto;	
}

#Middle .Content .ContentBox {
	background-color: #ffffff;
	border-radius: 5px;
	box-shadow: 0px 4px 35px rgba(0,0,0,0.1);	
}

	#Middle .Content .ContentBox:last-child {
		margin-bottom: 0;
	}

	#Middle .Content .ContentBox:before, #Middle .Content .ContentBox:after {
		content: "";
		display: table;
		clear: both;
	}

#Middle .Content .Main {
	padding-left: 20px;
	padding-right: 20px;
}

#errorBox {
	margin-bottom: 12px;
	padding-bottom: 12px;
}

