#DivLogo {
	width: 70px;
	height: 80px;
	background-color: #3F8BBF;
	text-align: center;
}

	#DivLogo #LinkIndex {
		padding-top: 20px;
		display: block;
	}

#Headline .Content {
	background-color: #ffffff;
	padding-top: 6px;
	position: fixed;
	z-index: 8;
	top: 0;
	left: 100px;
	right: 0;
}

	#Headline .Content .HeaderTitles {
		float: left;
	}

		#Headline .Content .HeaderTitles .Title {
			font-size: 18px;
			font-weight: bold;
			font-family: 'Oswald', sans-serif;
			line-height: 25px;
			padding-top: 8px;
		}

			#Headline .Content .HeaderTitles .Title .test {
				color: red;
				padding-left: 10px;
			}

		#Headline .Content .HeaderTitles .BreadCrumbNavigationTitle {
			font-size: 16px;
		}

		#Headline .Content .HeaderTitles a.BreadCrumbNavigationTitle:not(.Disabled):hover {
			color: #0872b0;
		}

		#Headline .Content .HeaderTitles .BreadCrumbNavigationText {
			font-size: 16px;
			margin-left: 6px;
		}

	#Headline .Content .HeaderInfoText {
		padding-left: 20px;
		padding-top: 8px;
		line-height: 25px;
		float: left;
		font-size: 18px;
		font-weight: bold;
		font-family: 'Oswald', sans-serif;
		color: red;
	}


	#Headline .Content .TopMenu {
		padding-left: 5px;
		font-size: 0px;
		white-space: nowrap;
	}

		#Headline .Content .TopMenu .MailMenu {
		}

		#Headline .Content .TopMenu button {
			background: none !important;
			border: none;
			padding: 0 !important;
			color: #b3b7cd;
			line-height: 70px;
			width: 67px;
			height: 70px;
			text-align: center;
			display: inline-block;
			cursor: pointer;
		}

			#Headline .Content .TopMenu button svg {
				margin-top: 12px;
				height: 35px;
				transition: height 0.2s;
			}


	#Headline .Content .UserName {
		float: right;
		text-align: right;
		background-color: #ffffff;
		position: absolute;
		right: 0;
	}

		#Headline .Content .UserName > div {
			display: inline-block;
			text-align: right;
			line-height: 18px;
			padding-top: 3px;
			vertical-align: top;
			height: 47px;
		}

			#Headline .Content .UserName > div.TopMenu {
				padding-top: 0;
				padding-right: 10px;
			}

		#Headline .Content .UserName span.Name {
			font-size: 16px;
			font-weight: bold;
			font-family: 'Oswald', sans-serif;
		}

		#Headline .Content .UserName span.Hds {
			font-size: 16px;
		}

		#Headline .Content .UserName span.Info {
			font-size: 12px;
		}

		#Headline .Content .UserName svg {
			height: 34px;
		}

		#Headline .Content .UserName .DownButton {
			width: 50px;
			cursor: pointer;
			text-align: center;
			margin: 8px 10px 0px 10px;
		}

			#Headline .Content .UserName .DownButton svg {
				height: 30px;
			}

			#Headline .Content .UserName .DownButton .k-icon {
				margin-top: 8px;
			}

		#Headline .Content .UserName a {
			color: black;
			text-decoration: none;
		}

			#Headline .Content .UserName a:hover {
				color: red;
			}


	#Headline .Content input {
		float: right;
		margin-top: 5px;
		margin-bottom: 10px;
	}

.centreSelection {
	z-index: 10003 !important;
}

.userMenu {
	margin-top: 19px !important;
}

.userMenu {
	background-color: #2f7eb6 !important;
	border-radius: unset !important;
	border: none !important;
	padding: 0 !important;
	box-shadow: -5px 5px 8px 0px rgba(0,0,0,0.15);
	color: #ffffff;
}

	.userMenu .userMenuInfo {
		color: #ffffff;
		margin: 10px 80px 10px 20px;
		white-space: nowrap;
	}

		.userMenu .userMenuInfo td:first-child {
			padding-right: 20px;
		}

		.userMenu .userMenuInfo td.name {
			font-weight: bold;
			font-family: 'Oswald', sans-serif;
		}

	.userMenu .k-menu {
		background-color: #3f8bbf !important;
		padding: 10px 20px 10px 40px !important;
		list-style: unset !important;
	}

		.userMenu .k-menu .k-item {
			display: list-item;
			color: rgba(255,255,255,.3) !important;
		}

			.userMenu .k-menu .k-item > .k-link {
				font-size: 16px;
				font-weight: 400;
				padding: 0px 5px 0px 5px;
				color: #ffffff;
				display: inline-block;
			}

		.userMenu .k-menu li.k-item > .k-link:hover,
		.userMenu .k-menu li.selected > .k-link {
			color: #000000 !important;
			background-color: #ffffff;
			border-color: #ffffff;
		}
