#MainMenu button {
	background: none;
	border: none;
	padding: 15px 0px 15px 0px;
	text-align: center;
	display: inline-block;
	cursor: pointer;	
	width: 70px;
}

	#MainMenu button:first-of-type {
		margin-top: 15px;
	}

	#MainMenu button.selected,
	#MainMenu button.active {
		background-color: #3f8bbf;
		box-shadow: -10px 5px 7px 5px rgba(0,0,0,0.2);
	}

	#MainMenu button:disabled svg *,
	#MainMenu button:disabled:hover svg * {
		fill: #333333;
		opacity: 0.4;
	}

	#MainMenu button svg {
		vertical-align: middle;
		transition: height 0.2s;
		width: 35px;
		height: 35px;
	}

		#MainMenu button svg * {
			fill: rgba(255, 255, 255, 0.8);
		}

	#MainMenu button:hover svg *,
	#MainMenu button.active svg *,
	#MainMenu button.selected svg * {
		fill: #ffffff;
	}


