.loginTableSecondFactor > div > div .loginBody {
	padding-top: 20px;
}

.loginTableSecondFactor .loginForm > div {
	margin-bottom: 10px;
	color: #fff;
}

.loginTableSecondFactor > div > div .loginBody .loginForm button {	
	margin-right: 20px;
	margin-top: 0px;
}

.loginTableSecondFactor > div > div .loginBody .loginForm button.yes {	
	margin-top: 20px;
}

.loginTableSecondFactor > div > div .loginBody .loginForm > label.k-form-field {
	margin-bottom: 0px;
}

.loginTableSecondFactor > div > div .loginBody .loginForm > label.k-form-field  > .k-input {
	width: unset;
	align-self: auto;
}

.loginTableSecondFactor > div > div .loginBody .loginForm > label > span > input {	
	height: 36px;	
}

.loginTableSecondFactor > div > div .loginBody .loginForm > label > button {
	margin-left: 10px;
	margin-right: 0px;
	width: 170px;
	height: 38px;
}

.loginTableSecondFactor > div > div .loginBody .loginForm > div> button {
	
}

	.loginTableSecondFactor > div > div .loginBody .loginForm > div > button:nth-child(2) {
		margin-right: 0px;
	}
