body {
	margin: 0;
	padding: 0;	
	background-color: white;
}

body,
.k-widget {
	font-family: 'Roboto', sans-serif !important;
}

legend {
	font-size: 18px !important;
	font-weight: bold !important;
	font-family: 'Oswald', sans-serif !important;
}