.menuPopupContent {
	border-radius: unset !important;
	margin: 0 !important;
	border: none !important;
	background-color: #3f8bbf !important;
	font-size: 16px;
	box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.15);
}

	.menuPopupContent > div {
		padding-left: 10px;
		padding-right: 10px;
		height: 325px;
	}

	.menuPopupContent .title {
		font-size: 18px;
		font-weight: 600;
		font-family: 'Oswald', sans-serif;
		color: #ffffff;
		margin-left: 13px;
		margin-right: 13px;
		padding-bottom: 5px;
		padding-top: 10px;
		margin-bottom: 5px;
		border-bottom: solid 1px rgba(255, 255, 255, 0.32);
		display: block;
	}

	