.centreDropDownList {
	width: 400px !important;
}

.centreDropDownListItem span {
	display: inline-block;
}

	.centreDropDownListItem span.code {
		width: 70px;
		font-weight: bold;
	}

	.centreDropDownListItem span.city {
		width: 180px;
	}

	.centreDropDownListItem span.doctor {
		width: 270px;
	}

.centreDropDownListValue {
	width: 100%;
}
.centreDropDownListValue span {
	padding-right: 10px;
}

	.centreDropDownListValue span.code {
		font-weight: bold;
	}

div.centreDropDownListPopup {
	z-index: 10004 !important;
}