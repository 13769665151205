.loginTable,
.loginTable > div {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.loginTable {
	background: url('images/loginBg.jpg') no-repeat center center fixed;
	background-size: cover;
}

	.loginTable > div {
		background-color: rgba(47, 126, 182, 0.74);
		position: relative;
	}

		.loginTable > div > div {
			color: white;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
			width: 450px;
			height: 380px;
		}

			.loginTable > div > div .loginHeader {
				background-color: #2f7eb6;
				width: 450px;
				height: 122px;
			}

				.loginTable > div > div .loginHeader > div {
					float: left;
					margin-left: 45px;
					margin-top: 40px;
				}

					.loginTable > div > div .loginHeader > div .title {
						font-size: 21px;
						font-weight: bold;
						font-family: 'Oswald', sans-serif;
					}

					.loginTable > div > div .loginHeader > div .subTitle {
						font-size: 16px;
						font-weight: normal;
					}

			.loginTable > div > div .loginBody {
				background-color: #3F8BBF;
				width: 450px;
				padding: 45px;
			}

				.loginTable > div > div .loginBody input,
				.loginTable > div > div .loginBody button {
					height: 47px;
					border: none;
				}

				.loginTable > div > div .loginBody .loginForm {
					padding: 0;
				}

					.loginTable > div > div .loginBody .loginForm fieldset {
						border: 0;
					}

					.loginTable > div > div .loginBody .loginForm .k-form-field {
						margin-bottom: 10px;
					}

					.loginTable > div > div .loginBody .loginForm button {
						width: 100%;
						background-color: #485963 !important;
						margin-top: 40px;
						margin-bottom: 10px;
					}

					.loginTable > div > div .loginBody .loginForm button,
					.loginTable > div > div .loginBody .loginForm input {
						border-radius: unset;
					}

				.loginTable > div > div .loginBody .valError {
					background-color: #C85856;
					color: #ffffff;
					text-align: left;
					padding-left: 20px;
					line-height: 30px;
					margin: 0 0 10px 0;
				}
