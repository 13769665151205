.valError {
	color: red;
	text-align: right;
	margin-top: -10px;
}

.valInputError {
	border-color: red !important;
}

.formLabel {
	font-size: 14px;
	font-weight: bold;
	display: block;
	float: left;
	padding-bottom: 20px;
}

.gridFilters .k-form-field {
	padding-right: 10px;
	margin-bottom: 0.5rem;
}

	.gridFilters .k-form-field > span {
		padding-right: 5px;
	}

.cleaner {
	clear: both;
}

.flex {
	display: flex;
}

.invisibleChildren > * {
	visibility: hidden;
}

label.k-form-field > span {
	white-space: nowrap;
}

.k-checkbox {
	display: inline-block; /*Jinak nefunguje spr�vn� v IE*/
}

.gridList {
	height: calc(100vh - 180px);	
}

.gridList .k-grid{
	height: calc(100vh - 190px);
}

.hidden {
	visibility: hidden;
}

.hide {
	display: none;
}

.break {
	flex-basis: 100%;
	height: 0;
}

button:hover svg * {
	fill: #4C4C4C;
}

	button:hover svg *.colorEdit,
	button:hover svg *.colorForward {
		fill: #2F7EB6;
	}

	button:hover svg *.colorDelete,
	button:hover svg *.colorMoveToListDelete {
		fill: #DB1456;
	}

	button:hover svg *.colorNew,
	button:hover svg *.colorReply,
	button:hover svg *.colorPatientNew,
	button:hover svg *.colorPatientReArrange,
	button:hover svg *.colorMoveToListList,
	button:hover svg *.colorPatientFromList {
		fill: #11BC9C;
	}

	button:hover svg *.colorReplyWhite,
	button:hover svg *.colorForwardWhite,
	button:hover svg *.colorPatientReArrangeWhite,
	button:hover svg *.colorMoveToListWhite,
	button:hover svg *.colorPatientFromListWhite {
		fill: #FFFFFF;
	}


button:disabled svg *,
button:disabled:hover svg * {
	fill: #b3b7cd;
	opacity: 0.7;
}

	button:disabled svg *.colorReplyWhite,
	button:disabled svg *.colorForwardWhite,
	button:disabled svg *.colorPatientReArrangeWhite,
	button:disabled svg *.colorMoveToListWhite,
	button:disabled svg *.colorPatientFromListWhite {
		fill: #FFFFFF;
	}

	button:disabled:hover svg *.colorReplyWhite,
	button:disabled:hover svg *.colorForwardWhite,
	button:disabled:hover svg *.colorPatientReArrangeWhite,
	button:disabled:hover svg *.colorMoveToListWhite,
	button:disabled:hover svg *.colorPatientFromListWhite {
		fill: #FFFFFF;
	}


.k-grid th.k-state-selected, .k-grid td.k-state-selected, .k-grid tr.k-state-selected > td {
	color: #ffffff;
	background-color: #3f8bbf;
}

.k-upload {
	margin-top: 20px;
	margin-bottom: 20px;
	border: none;
}

	.k-upload .k-dropzone {
		height: 200px;
		width: 400px;
		padding-top: 20px;
		padding-bottom: 180px;
		background: #EEEEEE url('images/upload.png') no-repeat center center;
		border: dashed 1px #B4B4B4;
		position: relative;
	}

		.k-upload .k-dropzone .k-upload-button {
			background-color: rgba(0, 0, 0, 0);
			margin: 0;
			padding: 0;
			display: block;
			position: absolute;
			top: -20px;
			left: -8px;
			width: 400px;
			height: 200px;
			border: none;
		}

			.k-upload .k-dropzone .k-upload-button > span {
				display: none;
			}

		.k-upload .k-dropzone .k-dropzone-hint {
			text-align: center;
		}

.editDialog .k-dialog-title {
	padding-bottom: 12px;
	border-bottom: solid 1px #EBEFF1;
	font-size: 18px;
	font-weight: bold;
	font-family: 'Oswald', sans-serif;
}

.editDialog .k-dialog-actions {
	display: none;
}

.editDialog .k-dialog-titlebar {
	border: none;
}

.editDialog .k-form-inline {
	padding-top: 0px;
}

div.cbox {
	background-color: #ffffff;
	padding: 20px 20px 0 20px;
	vertical-align: top;
}

div.cbox.homepage {	
	padding: 20px;
	margin-bottom: 12px;	
}

	div .cbox > div:first-child {
		padding-bottom: 12px;
		margin-bottom: 12px;
		border-bottom: solid 1px #EBEFF1;
		font-size: 18px;
		font-weight: bold;
		font-family: 'Oswald', sans-serif;
	}

		div.cbox > div:first-child > a,
		div.cbox > div:first-child > span:not(.k-icon) {
			float: right;
			color: #000000;
			font-size: 14px;
			font-weight: normal;
			text-decoration: none;
			font-family: 'Roboto', sans-serif;
		}

		div.cbox > div:first-child > span.k-icon {
			float: right;			
			font-size: 25px;	
			margin-top: 5px;
		}

.k-button.k-primary {
	background-color: #3f8bbf !important;
	border-color: #3f8bbf !important;
	border-radius: unset;
}

.k-tabstrip .k-item.k-state-default,
.k-tabstrip .k-item.k-state-active {
	font-size: 18px;
	font-family: 'Oswald', sans-serif;
}

.k-tabstrip .k-item.k-state-default {
	font-weight: 300;
	color: #3f8bbf;
}

.k-tabstrip .k-item.k-state-active {
	font-weight: 700;
}

.bold {
	font-weight: bold;
}


/*Zm�ny po p�echodu na react 18 a novou verzi telerik�*/
:root {
	--kendo-font-family: Roboto, sans-serif;
	--kendo-color-primary: #3f8bbf;
	--kendo-color-primary-hover: #3f8bbf;
}


.k-required {
	color: #007bff;
}

.k-input.k-textbox, .k-textarea, .k-textbox {
	border-radius: .25rem;
	padding: .375rem .75rem;
	width: 12.4em;
	box-sizing: border-box;
	border-width: 1px;
	border-style: solid;
	outline: 0;
	font: inherit;
	font-size: 1rem;
	line-height: 1.5;
	display: inline-flex;
	vertical-align: middle;
	position: relative;
	-webkit-appearance: none;
}

.k-form-inline .k-form-field > span.k-dropdownlist {
	width: 12.4em;
	padding: 0;
	text-align: start;
}

.k-button.k-primary {
	color: #fff;
}

.k-button {
	padding: 0.375rem;
}

.k-form-inline .k-form-field > span.k-input {	
	padding: 0;	
}

.k-datepicker {
	min-width: 160px !important;
}

.k-grid-norecords-template {
	border: 0;
}